define("admin/abilities/host-set", ["exports", "api/abilities/host-set"], function (_exports, _hostSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _hostSet.default;
    }
  });
});
