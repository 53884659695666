define("admin/components/form/authenticate/oidc", ["exports", "core/components/form/authenticate/oidc"], function (_exports, _oidc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _oidc.default;
    }
  });
});
