define("admin/initializers/deprecations", ["exports", "core/initializers/deprecations"], function (_exports, _deprecations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _deprecations.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _deprecations.initialize;
    }
  });
});
