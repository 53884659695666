define("admin/helpers/format-date-iso-human", ["exports", "core/helpers/format-date-iso-human"], function (_exports, _formatDateIsoHuman) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formatDateIsoHuman.default;
    }
  });
});
