define("admin/helpers/app-name", ["exports", "core/helpers/app-name"], function (_exports, _appName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _appName.default;
    }
  });
});
