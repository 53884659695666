define("admin/decorators/notify", ["exports", "core/decorators/notify"], function (_exports, _notify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _notify.default;
    }
  });
});
