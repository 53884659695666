define("admin/components/rose/toolbar/info", ["exports", "rose/components/rose/toolbar/info"], function (_exports, _info) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _info.default;
    }
  });
});
