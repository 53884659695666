define("admin/models/fragment-auth-method-attributes-account-claim-map", ["exports", "api/models/fragment-auth-method-attributes-account-claim-map"], function (_exports, _fragmentAuthMethodAttributesAccountClaimMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fragmentAuthMethodAttributesAccountClaimMap.default;
    }
  });
});
