define("admin/serializers/fragment-principal", ["exports", "api/serializers/fragment-principal"], function (_exports, _fragmentPrincipal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fragmentPrincipal.default;
    }
  });
});
