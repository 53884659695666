define("admin/helpers/format-date-iso", ["exports", "core/helpers/format-date-iso"], function (_exports, _formatDateIso) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _formatDateIso.default;
    }
  });
});
