define("admin/components/rose/layout/centered", ["exports", "rose/components/rose/layout/centered"], function (_exports, _centered) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _centered.default;
    }
  });
});
