define("admin/decorators/resource-filter", ["exports", "core/decorators/resource-filter"], function (_exports, _resourceFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _resourceFilter.default;
    }
  });
});
