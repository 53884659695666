define("admin/initializers/fragment-auth-method-attributes-account-claim-map-serializer", ["exports", "api/initializers/fragment-auth-method-attributes-account-claim-map-serializer"], function (_exports, _fragmentAuthMethodAttributesAccountClaimMapSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fragmentAuthMethodAttributesAccountClaimMapSerializer.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _fragmentAuthMethodAttributesAccountClaimMapSerializer.initialize;
    }
  });
});
